import _ from 'lodash';

export function isTimeValide(value) {
	if (_.isNil(value)) return false;
	if (value === '--:--') return false;

	return true;
}

export const isNumeric = (value) => {
	if (_.isNil(value)) return false;
	if (_.isNumber(value) === false) return false;

	return true;
};
