var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form" },
    [
      _vm.createByOtherUser
        ? _c(
            "b-row",
            [
              _c("b-col", [
                _c("div", { staticClass: "float-right" }, [
                  _c("div", { staticClass: "fs-14 fw-500" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(245, "Created by")) +
                        " " +
                        _vm._s(
                          _vm.transport.createdByUser.name +
                            " " +
                            _vm.transport.createdByUser.firstName
                        ) +
                        " -\n\t\t\t\t\t" +
                        _vm._s(
                          _vm._f("formatDate")(
                            _vm.transport.createdAt,
                            "DD/MM/YYYY HH:mm"
                          )
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      !_vm.isMyTransport
        ? _c(
            "b-form-group",
            { attrs: { label: _vm.FormMSG(68, "Crew member") } },
            [
              _c("v-select", {
                attrs: {
                  options: _vm.users,
                  placeholder: _vm.FormMSG(75, "Choose a user"),
                  reduce: (option) => option.id,
                  label: "fullName",
                  clearable: false,
                  disabled: _vm.createByOtherUser,
                },
                on: { input: _vm.handleInputChooseAUser },
                model: {
                  value: _vm.transport.userId,
                  callback: function ($$v) {
                    _vm.$set(_vm.transport, "userId", $$v)
                  },
                  expression: "transport.userId",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-form-group",
        { attrs: { label: _vm.FormMSG(69, "Choose a date") } },
        [
          _c("b-form-datepicker", {
            attrs: {
              id: "transport-date",
              locale: _vm.currentLang,
              disabled: _vm.createByOtherUser,
            },
            on: { input: _vm.handleInputChooseDate },
            model: {
              value: _vm.chooseDate,
              callback: function ($$v) {
                _vm.chooseDate = $$v
              },
              expression: "chooseDate",
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { label: _vm.FormMSG(102, "Description") } },
        [
          _c("b-form-textarea", {
            attrs: {
              id: "comment",
              rows: "3",
              disabled: _vm.createByOtherUser,
              state: !_vm.$v.transport.extraInfo.$error,
            },
            model: {
              value: _vm.$v.transport.extraInfo.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.transport.extraInfo, "$model", $$v)
              },
              expression: "$v.transport.extraInfo.$model",
            },
          }),
          _vm.$v.transport.extraInfo.$error === true
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(578, "Description is required")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { label: _vm.FormMSG(103, "Reason") } },
        [
          _c("b-form-select", {
            attrs: {
              id: "reason-transport",
              options: _vm.reasonMenues,
              disabled: _vm.createByOtherUser,
            },
            model: {
              value: _vm.transport.reason,
              callback: function ($$v) {
                _vm.$set(_vm.transport, "reason", $$v)
              },
              expression: "transport.reason",
            },
          }),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "12", lg: "12", xl: "12" } },
            [
              _c("vehicules-card", {
                attrs: {
                  disabled: _vm.createByOtherUser,
                  "edit-data": _vm.transport,
                  "can-add-and-edit": !_vm.isMyTransport,
                  "user-id": _vm.currentUserId,
                },
                on: { change: _vm.handleCarChange },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("transport-type-selector", {
                ref: "transportTypSelectorTransport",
                attrs: {
                  "data-additional-detail": _vm.transport.additionalSubType,
                  "data-detail": _vm.transport.subType,
                  "data-type": _vm.transport.type,
                },
                on: {
                  "transportation-type-selector:change":
                    _vm.handleTransportationTypeSelectorChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "5", lg: "5", xl: "5" } },
            [
              [9, 10, 11, 12, 19].includes(_vm.transportTypeMapped)
                ? _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(115, "Material weight") } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: { disabled: _vm.createByOtherUser },
                            model: {
                              value: _vm.transport.materialWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.transport, "materialWeight", $$v)
                              },
                              expression: "transport.materialWeight",
                            },
                          }),
                          _c("b-input-group-append", [
                            _c("div", { staticClass: "pl-3 fs-20 fw-700" }, [
                              _vm._v("Kg"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("address-maker", {
            attrs: {
              type: "from",
              "edit-data": _vm.transport,
              disabled: _vm.createByOtherUser,
              "show-my-address": _vm.isMyTransport,
            },
            on: { change: _vm.handleChangeFromAddress },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mb-3 mt-3" },
        [
          _c("address-maker", {
            attrs: {
              type: "to",
              "edit-data": _vm.transport,
              disabled: _vm.createByOtherUser,
              "show-my-address": _vm.isMyTransport,
            },
            on: { change: _vm.handleChangeToAddress },
          }),
        ],
        1
      ),
      !_vm.createByOtherUser
        ? _c(
            "div",
            [
              _vm.showGooglePlanification
                ? _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "fieldset",
                          {
                            staticClass:
                              "scheduler-border border-groove-blue-streak pb-0 bg-color-grams-hair-color",
                          },
                          [
                            _c(
                              "legend",
                              {
                                staticClass:
                                  "scheduler-border text-color-blue-streak",
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(145, "Google planification")
                                    ) +
                                    "\n\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      cols: _vm.$screen.width < 567 ? 6 : 3,
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                          disabled:
                                            _vm.createByOtherUser ||
                                            _vm.cantUseGooglePlanification,
                                        },
                                        model: {
                                          value: _vm.avoidHighways,
                                          callback: function ($$v) {
                                            _vm.avoidHighways = $$v
                                          },
                                          expression: "avoidHighways",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(165, "Avoid highways")
                                            ) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      cols: _vm.$screen.width < 567 ? 6 : 3,
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                          disabled:
                                            _vm.createByOtherUser ||
                                            _vm.cantUseGooglePlanification,
                                        },
                                        model: {
                                          value: _vm.avoidTolls,
                                          callback: function ($$v) {
                                            _vm.avoidTolls = $$v
                                          },
                                          expression: "avoidTolls",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(166, "Avoid tolls")
                                            ) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mt-3" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      sm: "12",
                                      md: "5",
                                      lg: "5",
                                      xl: "5",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(167, "Preference"),
                                        },
                                      },
                                      [
                                        _c("b-form-select", {
                                          attrs: {
                                            options: _vm.datesOptionsMap,
                                            disabled:
                                              _vm.createByOtherUser ||
                                              _vm.cantUseGooglePlanification,
                                          },
                                          model: {
                                            value: _vm.transport.depArrStd,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transport,
                                                "depArrStd",
                                                $$v
                                              )
                                            },
                                            expression: "transport.depArrStd",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      sm: "12",
                                      md: "5",
                                      lg: "5",
                                      xl: "5",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            169,
                                            "Choose a time"
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "time",
                                                state:
                                                  _vm.chooseTimeState.state,
                                                disabled:
                                                  _vm.createByOtherUser ||
                                                  _vm.cantUseGooglePlanification,
                                              },
                                              on: {
                                                input:
                                                  _vm.handleInputChooseTime,
                                              },
                                              model: {
                                                value: _vm.chooseTime,
                                                callback: function ($$v) {
                                                  _vm.chooseTime = $$v
                                                },
                                                expression: "chooseTime",
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "Clock"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color: "#06263E",
                                                          size: 16,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.chooseTimeState.state === false
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "invalid-feedback",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.chooseTimeState
                                                            .message
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  {
                                    class:
                                      _vm.$screen.width > 567
                                        ? "pt-28"
                                        : "mb-3",
                                    attrs: {
                                      sm: "12",
                                      md: "2",
                                      lg: "2",
                                      xl: "2",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          size: "sm",
                                          block: "",
                                          disabled:
                                            _vm.disableComputeBtn ||
                                            _vm.isDurationEstimationLoading ||
                                            _vm.createByOtherUser ||
                                            _vm.cantUseGooglePlanification,
                                        },
                                        on: { click: _vm.plannifiedWithGoogle },
                                      },
                                      [
                                        _vm.isDurationEstimationLoading
                                          ? _c("b-spinner", {
                                              attrs: {
                                                small: "",
                                                label: "Loading...",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(178, "Compute")
                                            ) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(109, "Travel distance") } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "10" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "number",
                              state: !_vm.$v.transport.km.$error,
                              disabled: _vm.createByOtherUser,
                            },
                            on: { change: _vm.handleChangeTravelDistance },
                            model: {
                              value: _vm.$v.transport.km.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.transport.km, "$model", $$v)
                              },
                              expression: "$v.transport.km.$model",
                            },
                          }),
                          _vm.$v.transport.km.$error === true
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        78,
                                        "Travel distance is required"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "2" } }, [
                        _c("div", { staticClass: "fs-20 fw-700" }, [
                          _vm._v(_vm._s(_vm.labelKm)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(110, "Green impact (Kg CO2)") } },
                [
                  _c("b-form-input", {
                    attrs: { disabled: _vm.createByOtherUser },
                    model: {
                      value: _vm.greenImpact,
                      callback: function ($$v) {
                        _vm.greenImpact = $$v
                      },
                      expression: "greenImpact",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(111, "Departure time") } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          type: "time",
                          disabled: _vm.createByOtherUser,
                        },
                        on: { input: _vm.handleInputDepartureTime },
                        model: {
                          value: _vm.departureTime,
                          callback: function ($$v) {
                            _vm.departureTime = $$v
                          },
                          expression: "departureTime",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            [
                              _c(_vm.getLucideIcon("Clock"), {
                                tag: "component",
                                attrs: { color: "#06263E", size: 16 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(112, "Arrival time") } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          type: "time",
                          disabled: _vm.createByOtherUser,
                        },
                        on: { input: _vm.handleInputArrivalTime },
                        model: {
                          value: _vm.arrivalTime,
                          callback: function ($$v) {
                            _vm.arrivalTime = $$v
                          },
                          expression: "arrivalTime",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            [
                              _c(_vm.getLucideIcon("Clock"), {
                                tag: "component",
                                attrs: { color: "#06263E", size: 16 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(113, "Duration") } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: { value: _vm.durationTime, disabled: "" },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            [
                              _c(_vm.getLucideIcon("Clock"), {
                                tag: "component",
                                attrs: { color: "#06263E", size: 16 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mb-5" },
        [
          _c("DriverAndPassengerCard", {
            attrs: {
              disabled: _vm.createByOtherUser,
              transport: _vm.transport,
            },
            on: { "car-change": _vm.handleCarChange },
          }),
        ],
        1
      ),
      !_vm.hideActionFooter
        ? _c(
            "b-row",
            { staticClass: "footer-fixed" },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "fs-16 fw-700 bg-color-grams-hair-color",
                      attrs: { block: "", variant: "none" },
                      on: { click: _vm.handleClickBack },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(117, "Cancel")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "fs-16 fw-700",
                      attrs: { variant: "primary", block: "" },
                      on: { click: _vm.handleClickSave },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(116, "Save")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }